
import axios from "axios";

const domain = process.env.VUE_APP_DOMAIN ?? 'https://us-central1-gestio-school-dev.cloudfunctions.net';
axios.defaults.baseURL = domain;

export default {
    create(payload) {
        return axios.post("v1_genesis/v1/budgets_devolutions_books/create", payload);
    },
    update( payload) {
        return axios.put(`v1_genesis/v1/budgets_devolutions_books/update/${payload.id_budgets_devolutions}/${payload.id_budgets_books_books}/${payload.id_budgets_books_budgets}`, payload);
    },
    remove(id_budgets_devolutions,id_budgets_books_books,id_budgets_books_budgets) {
        return axios.delete(`v1_genesis/v1/budgets_devolutions_books/remove/${id_budgets_devolutions}/${id_budgets_books_books}/${id_budgets_books_budgets}`);
    },
    delete(id_budgets_devolutions,id_budgets_books_books,id_budgets_books_budgets) {
        return axios.delete(`v1_genesis/v1/budgets_devolutions_books/delete/${id_budgets_devolutions}/${id_budgets_books_books}/${id_budgets_books_budgets}`);
    },
    get(id_budgets_devolutions,id_budgets_books_books,id_budgets_books_budgets) {
        return axios.get(`v1_genesis/v1/budgets_devolutions_books/${id_budgets_devolutions}/${id_budgets_books_books}/${id_budgets_books_budgets}`);
    },
    paginate(query) {
        return axios.get(`v1_genesis/v1/budgets_devolutions_books/paginate`, { params: query });
    },
    all() {
        return axios.get(`v1_genesis/v1/budgets_devolutions_books/`);
    }
}
